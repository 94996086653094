<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right">Công ty <span style="color: red">*</span></label>
            <div class="p-col">
              <MultiSelect class="multiselect input_text border_color " id="company" v-model="selectedCompany" :options="company" optionLabel="code" optionValue="id" placeholder="Chọn" />
              <span slot="no-options" @click="$refs.select.open = false">
              </span>
              <input class="input_tmp_validator" id="vali_company">
            </div>
          </div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right">Tên kênh phân phối <span style="color: red">*</span></label>
            <div class="p-col">
              <Textarea :autoResize="true"  rows="1" maxlength="255" v-model="channel.name"  id="name" type="text" class="p-col-width border_color text-area" placeholder="Nhập " />
            </div>
          </div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right ">Mã kênh <span style="color: red">*</span></label>
            <div class="p-col">
              <InputText maxlength="50" required v-model="channel.code"  id="code" type="text" class="p-col-width input_text border_color" placeholder="Nhập " />
            </div>
          </div>
        <div class="p-card-field">
            <label class="p-col-fixed txt-right ">Quy chuẩn mã tiền tố khách hàng  <span style="color: red">*</span></label>
            <div class="p-col">
              <InputText maxlength="2" @input="validateNumericInput" required v-model="channel.prefix_code_cus"  id="prefix_code_cus" type="text" class="p-col-width input_text border_color" placeholder="Nhập " />
            </div>
          </div>
        <div class="p-card-field">
            <div class="p-col">
              <Checkbox  v-model="channel.checked" @click="active" :binary="true"/>
              <label class="p-col-fixed txt-right text">Hiển thị</label>
            </div>
          </div>
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      channel:{
        name: '',
        code:'',
        prefix_code_cus:'',
        checked: true,
      },
      selectedCompany: null,
      company: [],
      code: '',
      test:'',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {
    console.log("sanggngn")




    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      var list_channel_edit = this.$commonFuction.convertJsonObject(this.model);
      this.channel.name = this.model.name
      this.channel.code = this.model.code
      this.channel.prefix_code_cus = this.model.prefix_code_cus
      this.channel.checked = this.model.active
      console.log("sanggngn",this.channel.checked)

        var list_channel_company = (list_channel_edit.channel_companies) ? list_channel_edit.channel_companies:[];
        console.log("wqwqwqwqwer",list_channel_company)
        var array = [];
        for (var i = 0; i < list_channel_company.length; i++) {
          console.log("sdsds412121",list_channel_company[i].company_id)
          array.push(list_channel_company[i].company_id)

        }
        this.selectedCompany = array;
       }
      console.log("sdsds" ,this.selectedCompany)
      var gqLQueryList = DataServices.getList('company');
      console.log("gqL",gqLQueryList)
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
    this.company = resData.data['company'];
      console.log("list data11", this.company);
    // }
  },
  methods: {
    async active() {
    },

    async validateNumericInput() {
      this.channel.prefix_code_cus = this.channel.prefix_code_cus.replace(/[^\w\s]/gi, "");
      this.channel.prefix_code_cus = this.channel.prefix_code_cus.replace(/\d/g, '');
      // this.channel.prefix_code_cus = this.channel.prefix_code_cus.replace(/[^!@#$%^&*()-_+=]/g, '');
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let company_add = document.getElementById("vali_company");
      if (this.$commonFuction.isEmptyObject(this.selectedCompany)){
        company_add.setCustomValidity("Công ty không được để trống!");
      }else {
        company_add.setCustomValidity("");
      }

      let name_add = document.getElementById("name");
      console.log("mmmm",name_add)
      console.log("mmmmwq",this.channel.name)
      if (this.channel.name == undefined || this.channel.name == null || this.channel.name == ''){
        name_add.setCustomValidity("Tên không được để trống!");
      }else {
        name_add.setCustomValidity("");
      }

      let code_add = document.getElementById("code");


      if (this.channel.code == undefined || this.channel.code == null || this.channel.code == ''){
        code_add.setCustomValidity("Mã không được để trống!");
      }else {
        code_add.setCustomValidity("");
      }


      let prefix_code_cus_add = document.getElementById("prefix_code_cus");
      if (this.channel.prefix_code_cus == undefined || this.channel.prefix_code_cus == null || this.channel.prefix_code_cus == ''){
        prefix_code_cus_add.setCustomValidity(" Quy chuẩn mã tiền tố khách hàng không được để trống!");
      }else {
        prefix_code_cus_add.setCustomValidity("");
      }



      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }


      var list_validate = DataServices.getList('channel');
      console.log("gqL",list_validate)
      var res_validate = await this.$apollo.mutate({
        mutation: list_validate,
        variables: {
          where_key: {deleted: {_eq: false}},
          orderBy: {created_at: 'desc'},

        }
      });
      var list_channel = res_validate.data['channel'];
      console.log("sasas3223200",list_channel)
      var check_code = ''
      var check_code_new = ''
      if (this.mode == "edit")
      {
        var channel_check = this.$commonFuction.convertJsonObject(this.model);
        console.log("wqwsasasasa",channel_check)
        console.log("sawqwqwqw",channel_check)
        for (var j = 0 ; j< list_channel.length ; j++)
        {
          //  lấy mã từ danh sach ra kiểm tra
          check_code = list_channel[j].code ? list_channel[j].code.toLowerCase():""
          // lấy dữ liệu người dùng nhập vào
          check_code_new = this.channel.code ? this.channel.code.toLowerCase():""
          console.log("check_code:",check_code , "check_code_new",check_code_new)
          if (check_code == check_code_new && list_channel[j].id != channel_check.id)
          {
            alert("Mã kênh này đã tồn tại. Vui lòng thử lại")
            return
          }
        }
      }

      if (this.mode == "add")
      {
        console.log("aaaadddd")
        for (var k = 0 ; k< list_channel.length ; k++)
        {
          //  lấy mã từ danh sach ra kiểm tra
          check_code = list_channel[k].code ? list_channel[k].code.toLowerCase():""
          // lấy dữ liệu người dùng nhập vào
          check_code_new = this.channel.code ? this.channel.code.toLowerCase():""
          if (check_code == check_code_new)
          {
            alert("Mã Kênh này đã tồn tại. Vui lòng thử lại")
            return
          }
        }
      }



       console.log("test4")
      console.log("checked",this.checked)

      var company_id = [];

       console.log("timid",this.selectedCompany)
      this.selectedCompany ? this.selectedCompany:0
      if (this.selectedCompany == 0 || this.selectedCompany == null )
      {
        alert(" Công ty không được trống !!")
        return;
      }
      var length = this.selectedCompany ? this.selectedCompany :[]
      for( var i = 0 ; i < length.length ; i++)
      {
        company_id.push(this.selectedCompany[i])
      }

        var params = {
          name  :this.channel.name,
          code:this.channel.code,
          prefix_code_cus:this.channel.prefix_code_cus,
          company_id:company_id,
          active:this.channel.checked
        }
      console.log('saveData', params)
        // console.log("params:",params);
        // console.log("do dai ",this.selectedCompany.length);
        // console.log("selected company",this.selectedCompany[0].id)
        // console.log("company id",params.company_id);
        // console.log(params);
      // var name = (params.name.length) ? params.name.length:0
      // var code = (params.code.length) ? params.code.length:0
      console.log("nasnasasas",name)
console.log("sâsaaa");
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

//style form add-edit
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.text-area{
  width: 760px!important;
  min-height: 48px;
}
.txt-right{
  font-weight: bold;
}
.p-formgrid .fixed-bottom-toolbar{
  border-top: none!important;
}
.text{
  font-weight: normal;
}
.layout-main{
  background: #00000029;
}

.p-button-warning{
  border: 1px solid #C40380 !important;
}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}
.card{
  padding: 16px!important;
}
.border_color{
  border: 1px solid #D0D4D9!important;
}
.p-multiselect .p-multiselect-label{
  padding: 10px 10px 10px 7px!important;
}




//responsive
@media screen and (max-width: 600px) {
  //.layout-wrapper .layout-sidebar{
  //  left: -1000px!important;
  //}
  .input_text{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
}

@media screen and (max-width: 1000px) {
  .input_text{
    width: 100%!important;
  }
  .text-area{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
}
</style>
